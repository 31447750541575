import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  CreateProAssignmentRequestNoteResp,
  CreateProAssignmentRequestNoteRqst,
  CreateProAssignmentRequestResp,
  CreateProPrintLabelOrderResp,
  GetProAssignmentRequestPath,
  GetProAssignmentRequestResp,
  GetProMetricsPath,
  GetProMetricsQuery,
  GetProMetricsResp,
  LabelVendor,
  ListLabelVendorsQuery,
  ListLabelVendorsResp,
  ListProAssignmentRequestsResp,
  ProNumberReengineeringApiService,
  ShipApprovedProAssignmentsRqst,
  UpdateProAssignmentRequestDetailPath,
  UpdateProAssignmentRequestDetailResp,
  UpdateProAssignmentRequestDetailRqst,
  UpdateProAssignmentRequestPath,
  UpdateProAssignmentRequestResp,
  UpdateProAssignmentRequestRqst,
  UploadProOrderRqst,
} from '@xpo-ltl/sdk-pronumberreengineering';
import { catchError, map } from 'rxjs/operators';
import { FileContents, ProPadTypeCd, ProRequestStatusCd } from '@xpo-ltl/sdk-common';
import { MetricType } from '../../shared/enums/metric-type.enum';

@Injectable({ providedIn: 'root' })
export class ProInventoryService {
  constructor(private proNumberReengineeringApiService: ProNumberReengineeringApiService) {}

  quantityDetails: BehaviorSubject<any> = new BehaviorSubject(null);

  createProLabelRequest(request: any): Observable<CreateProAssignmentRequestResp> {
    return this.proNumberReengineeringApiService.createProAssignmentRequest(request, { toastOnError: false }).pipe(
      map((result: CreateProAssignmentRequestResp) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  getMetric(
    proLabelFormatTypeCd: string,
    sicCd: string,
    proPadTypeCd: ProPadTypeCd,
    metricType: MetricType,
    statusCd?: ProRequestStatusCd,
    timeRangeSince?: any,
    timeRangeUntil?: any,
    assignedToCustomerCareManager?: boolean,
    madCd?: string,
    requestByEmployeeId?: string,
    labelVendorCd?: string
  ): Observable<GetProMetricsResp> {
    const pathParams: GetProMetricsPath = {
      proLabelFormatTypeCd: proLabelFormatTypeCd,
      metricsTypeCd: metricType,
    };
    const queryParams: GetProMetricsQuery = new GetProMetricsQuery();

    queryParams.sicCd = sicCd;
    queryParams.proPadTypeCd = proPadTypeCd;
    queryParams.statusCd = statusCd;
    queryParams.timeRangeSince = timeRangeSince;
    queryParams.timeRangeUntil = timeRangeUntil;
    queryParams.assignedToCustomerCareManager = assignedToCustomerCareManager;
    queryParams.requestByEmployeeId = requestByEmployeeId;
    queryParams.madCd = madCd;
    queryParams.labelVendorCd = labelVendorCd;

    return this.proNumberReengineeringApiService.getProMetrics(pathParams, queryParams).pipe(
      map((result: GetProMetricsResp) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  getProAssignmentRequests(
    sicCd,
    statusCd,
    timeRangeSince,
    timeRangeUntil,
    labelFormat,
    madCd?,
    assignedToCustomerCareManager?
  ): Observable<ListProAssignmentRequestsResp> {
    const queryParams: any = {
      sicCd: sicCd,
      statusCd: statusCd,
      timeRangeSince: timeRangeSince,
      timeRangeUntil: timeRangeUntil,
      proLabelFormatTypeCd: labelFormat,
      madCd: madCd,
      assignedToCustomerCareManager: assignedToCustomerCareManager,
    };
    return this.proNumberReengineeringApiService.listProAssignmentRequests(queryParams).pipe(
      map((result: ListProAssignmentRequestsResp) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  getProAssignmentRequest(proAssignmentRequestId): Observable<GetProAssignmentRequestResp> {
    const pathParams: GetProAssignmentRequestPath = {
      proAssignmentRequestId: proAssignmentRequestId,
    };
    return this.proNumberReengineeringApiService.getProAssignmentRequest(pathParams).pipe(
      map((result: GetProAssignmentRequestResp) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  updateProAssignmentRequest(assignmentRequest, proAssignmentRequestId): Observable<UpdateProAssignmentRequestResp> {
    const pathParams: UpdateProAssignmentRequestPath = {
      proAssignmentRequestId: proAssignmentRequestId,
    };
    const request: UpdateProAssignmentRequestRqst = {
      assignmentRequest: assignmentRequest,
    };
    return this.proNumberReengineeringApiService.updateProAssignmentRequest(request, pathParams).pipe(
      map((result: UpdateProAssignmentRequestResp) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  updateProAssignmentRequestDetail(
    assignmentRequestDetail,
    proAssignmentRequestId,
    proAssignRequestDetailSequenceNbr,
    assignmentRequestDetailNote
  ): Observable<UpdateProAssignmentRequestDetailResp> {
    const pathParams: UpdateProAssignmentRequestDetailPath = {
      proAssignmentRequestId: proAssignmentRequestId,
      proAssignRequestDetailSequenceNbr: proAssignRequestDetailSequenceNbr,
    };
    const request: UpdateProAssignmentRequestDetailRqst = {
      assignmentRequestDetail: assignmentRequestDetail,
      // assignmentRequestDetailNote: assignmentRequestDetailNote,
    };
    return this.proNumberReengineeringApiService.updateProAssignmentRequestDetail(request, pathParams).pipe(
      map((result: UpdateProAssignmentRequestDetailResp) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  createPrintLabelOrder(order): Observable<CreateProPrintLabelOrderResp> {
    return this.proNumberReengineeringApiService.createProPrintLabelOrder(order).pipe(
      map((result: CreateProPrintLabelOrderResp) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  createProAssignmentRequestNote(assignmentRequestNote): Observable<CreateProAssignmentRequestNoteResp> {
    const request: CreateProAssignmentRequestNoteRqst = {
      assignmentRequestNote: assignmentRequestNote,
    };
    return this.proNumberReengineeringApiService.createProAssignmentRequestNote(request).pipe(
      map((result: CreateProAssignmentRequestNoteResp) => {
        return result;
      }),
      catchError(this.handleError)
    );
  }

  handleError(err: any): Observable<never> {
    if (err && err.error && err.error.moreInfo && err.error.moreInfo.length) {
      return throwError(err.error.moreInfo[0].message);
    }
    return throwError(err || 'backend serve error');
  }

  handleErrorInfo(err: any): Observable<never> {
    if (err && err.error && err.error.moreInfo && err.error.moreInfo.length) {
      return throwError(err.error.moreInfo.map((info) => info.message).join(' \n '));
    }
    return throwError(err || 'backend serve error');
  }

  getListLabelVendors(): Observable<LabelVendor[]> {
    const queryParams: ListLabelVendorsQuery = new ListLabelVendorsQuery();
    return this.proNumberReengineeringApiService.listLabelVendors(queryParams).pipe(
      map((result: ListLabelVendorsResp) => {
        return result.labelVendors;
      }),
      catchError(this.handleError)
    );
  }

  shipApprovedAssignmentRequests(vendor: string) {
    const request: ShipApprovedProAssignmentsRqst = new ShipApprovedProAssignmentsRqst();
    request.labelVendorCd = vendor;
    return this.proNumberReengineeringApiService.shipApprovedProAssignments(request).pipe(catchError(this.handleError));
  }
  uploadFile(file: FileContents) {
    const request: UploadProOrderRqst = new UploadProOrderRqst();
    request.file = file;
    return this.proNumberReengineeringApiService.uploadProOrder(request).pipe(catchError(this.handleErrorInfo));
  }
}
