import { Component, Output, Input, EventEmitter, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { ProPadTypeCd } from '@xpo-ltl/sdk-common';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';
import { NoteType } from '../../enums/note-type.enum';
import { ProInventoryService } from '../../../core/services/pro-inventory.service';
import _moment from 'moment';
import { UserService } from '../../../core/services/user.service';
import { Note } from '../../models/note';
import { MatTabChangeEvent, ErrorStateMatcher } from '@angular/material';
import { RowNode } from 'ag-grid-community';
import { Validators, FormControl, AbstractControl } from '@angular/forms';
const moment = _moment;

@Component({
  selector: 'conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss'],
})
export class ConversationComponent extends UnsubscribeComponent implements OnChanges {
  rowData: any;
  sic: string;
  shipToSic: string;
  quantity: number = 0;
  status: string;
  requestId: number;
  labelType: string = '';
  note: any;
  detailId: number;
  notes: Note[];
  reasons = [];
  comments: Note[];
  receiverName = '';
  currentEmployeeId = '';
  loading = false;
  currentMessages: Note[];
  selectedTab: number;
  sendCommentsClicked = false;
  toggleAddNoteSectionButtonTitle: string;
  sendButtonTitle: string;
  madCode: string;
  textControl = new FormControl('', {
    validators: [Validators.required, this.noOnlyWhiteSpaceValidator],
  });
  matcher = new CustomStateMatcher();

  @Output() closeEvent = new EventEmitter<any>();
  @Output() addNoteEvent = new EventEmitter<any>();
  @Input('selectedAction') action: string;
  @Input('data') data: RowNode;
  @Input('canAddNote') canAddNote: boolean;
  @Input('canSendEmail') canSendEmail: boolean;
  @Input('electronicRequest') isElectronic: boolean;
  @ViewChild('messages', { static: false }) messages;

  constructor(private proInventoryService: ProInventoryService, private userService: UserService) {
    super();
    this.notes = [];
    this.comments = [];
    this.currentMessages = [];
    this.reasons = [];
  }

  private shouldUpdateData(changes: SimpleChanges): boolean {
    let should = false;
    const dataChanges: any = changes.data || {};
    const { previousValue, currentValue } = dataChanges;
    if (
      (currentValue && !previousValue) ||
      (currentValue && previousValue && currentValue.rowIndex !== previousValue.rowIndex)
    ) {
      should = true;
    }
    return should;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedTab = this.action === NoteType.AddNote ? 0 : 1;
    this.toggleAddNoteSectionButtonTitle = this.selectedTab ? 'SEND EMAIL' : 'ADD NEW NOTE';
    this.sendButtonTitle = this.selectedTab ? 'SEND EMAIL' : 'SAVE THIS NOTE';
    if (this.shouldUpdateData(changes)) {
      this.loading = true;
      this.notes = [];
      this.comments = [];
      this.currentMessages = [];
      this.reasons = [];
      if (this.data) {
        this.rowData = this.data;
        this.requestId = this.rowData.node.data.proAssignmentRequestId;
        this.detailId = this.rowData.node.data.proAssignRequestDetailSequenceNbr;
        this.sic = this.isElectronic
          ? this.rowData.data.requestedBySicCd
          : this.rowData.node.parent.data.requestedBySicCd;
        this.shipToSic = this.isElectronic ? this.rowData.data.shipToSicCd : this.rowData.node.parent.data.shipToSicCd;
        this.status = this.rowData.node.data.statusCd;
        this.currentEmployeeId = this.userService.user.employeeId;
        this.madCode = this.rowData.data.madCd;

        this.proInventoryService
          .getProAssignmentRequest(this.requestId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(
            (response: any) => {
              this.loading = false;

              for (const note of response.assignmentRequest.assignmentRequestNote) {
                if (note.proAssignRequestDetailSequenceNbr === this.detailId) {
                  if (note.noteTypeCd === NoteType.AddNote) {
                    this.notes.unshift(note);
                  } else if (note.noteTypeCd === NoteType.CommentNote) {
                    this.comments.unshift(note);
                  }
                }
              }

              this.currentMessages = this.selectedTab ? this.comments : this.notes;

              this.receiverName = this.getName(
                response.assignmentRequest.assignmentRequestNote.find(
                  (x) => x.noteByEmployeeId !== this.currentEmployeeId
                )
              );
            },
            (error) => {
              this.loading = false;
            }
          );

        this.labelType = this.textLabelCd(this.rowData.node.data.labelPadTypeCd);
        this.quantity = this.rowData.node.data.approvedQuantity || this.rowData.node.data.requestedQuantity;
      } else {
        this.loading = false;
        this.currentMessages = this.selectedTab ? this.comments : this.notes;
      }
    }
  }

  closeSideBar() {
    this.sendCommentsClicked = false;
    this.closeEvent.emit();
  }

  textLabelCd(code: ProPadTypeCd) {
    switch (code) {
      case ProPadTypeCd.US_BLUE_YELLOW:
        return 'US, B&Y';
      case ProPadTypeCd.CA_BLUE_YELLOW:
        return 'CA, B&Y';
      case ProPadTypeCd.US_BLUE:
        return 'US, Blue';
      case ProPadTypeCd.CA_BLUE:
        return 'CA, Blue';
      case ProPadTypeCd.YELLOW:
        return 'Yellow';
    }
  }

  sendText() {
    if (this.textControl.invalid) {
      return;
    }
    this.loading = true;
    const assignmentRequestNote = {
      proAssignmentRequestId: this.requestId,
      proAssignRequestDetailSequenceNbr: this.detailId,
      note: this.textControl.value,
      noteTypeCd: this.selectedTab ? NoteType.CommentNote : NoteType.AddNote,
    };
    this.proInventoryService
      .createProAssignmentRequestNote(assignmentRequestNote)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (response) => {
          this.loading = false;
          this.toggleAddNoteButtonGroup();
          if (response.assignmentRequestNote.noteTypeCd === NoteType.CommentNote) {
            this.comments.unshift(response.assignmentRequestNote as Note);
          } else {
            this.notes.unshift(response.assignmentRequestNote as Note);
          }
          this.addNoteEvent.emit(response.assignmentRequestNote);
        },
        (error) => {
          this.loading = false;
        }
      );
  }

  formatDate(dateTime) {
    return moment(dateTime).format('L LT');
  }

  getName(note) {
    if (note) {
      return note.noteByEmployeeDtl && note.noteByEmployeeDtl.firstName && note.noteByEmployeeDtl.lastName
        ? `${note.noteByEmployeeDtl.firstName} ${note.noteByEmployeeDtl.lastName}`
        : note.auditInfo.createByPgmId;
    } else {
      return '';
    }
  }

  onTabChanged(currentTab: MatTabChangeEvent) {
    this.selectedTab = currentTab.index;
    this.toggleAddNoteSectionButtonTitle = this.selectedTab ? 'CREATE NEW EMAIL' : 'ADD NEW NOTE';
    this.sendButtonTitle = this.selectedTab ? 'SEND EMAIL' : 'SAVE THIS NOTE';
    this.sendCommentsClicked = false;

    if (currentTab.index === 0) {
      this.currentMessages = this.notes;
    } else {
      this.currentMessages = this.comments;
    }
    this.messages.nativeElement.scrollTo(0, this.messages.nativeElement.scrollHeight);
  }

  toggleAddNoteButtonGroup() {
    this.textControl.reset('');
    this.sendCommentsClicked = !this.sendCommentsClicked;
  }

  noOnlyWhiteSpaceValidator(control: AbstractControl) {
    return !control.value.trim() ? { whiteSpacesOnly: true } : null;
  }
}

export class CustomStateMatcher implements ErrorStateMatcher {
  isErrorState(control: AbstractControl): boolean {
    return control.invalid && control.touched;
  }
}
