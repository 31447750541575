import {
  Component,
  OnInit,
  Host,
  Optional,
  SkipSelf,
  Input,
  AfterViewChecked,
  ChangeDetectorRef,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  ControlContainer,
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import {
  LocationApiService,
  ListLocationsRqst,
  ListLocationsQuery,
  GetZoneAndSatelliteBySicQuery,
  GetZoneAndSatelliteBySicPath,
} from '@xpo-ltl-2.0/sdk-location';
import { map, startWith, finalize, take, takeUntil, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material';
import { UserService } from '../../../core/services/user.service';
import { UserRole } from '../../enums/user-role/user-role.enum';
import { UnsubscribeComponent } from '../unsubscribe/unsubscribe.component';
import { LocationService } from '../../../core/services/location.service';

@Component({
  selector: 'app-sic-input',
  templateUrl: './sic-input.component.html',
  styleUrls: ['./sic-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SicInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SicInputComponent,
      multi: true,
    },
  ],
})
export class SicInputComponent extends UnsubscribeComponent
  implements OnInit, AfterViewChecked, AfterViewInit, ControlValueAccessor {
  serviceCenters: any[] = [];
  serviceCenter: string = null;
  isLoading: boolean = true;
  control: FormControl;
  options$: Observable<any[]>;
  errorMatcher = new CustomErrorStateMatcher();
  readOnlySic = false;
  customAssignmentElectronic: any = [];

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    protected controlContainer: ControlContainer,
    protected userService: UserService,
    protected changeDetector: ChangeDetectorRef,
    protected locationApiService: LocationApiService,
    protected locationServiceRules: LocationService
  ) {
    super();
  }

  @Input() formControlName: string;
  @Input() type: string;
  @Input() userRole: string;
  @Input() electronic: boolean;
  @Input() requestAdmin: boolean;
  @Input() physicalLocOnly: boolean = false;
  @Output() zonesEmitter = new EventEmitter<any>();
  @Output() changeSic = new EventEmitter<any>();

  onChange: Function = () => {};
  onTouched: Function = () => {};

  ngOnInit(): void {
    this.getControl();
    this.filterOptions();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    if (this.type === 'select' || this.type === 'special-rules') {
      this.setZones('');
    } else {
      this.setServiceCenter();
    }
    this.checkRole();
  }

  protected getControl(): void {
    /* istanbul ignore else */
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName) as FormControl;
        this.control.clearValidators();
      } else {
        console.warn('Missing FormControlName directive from component host element');
      }
    } else {
      /* istanbul ignore next */
      console.warn('Cannot find parent FormGroup directive');
    }
  }

  validate({ value }: FormControl): any {
    if (!value) {
      return { invalid: true };
    }
    if (!this.serviceCenters.some((sc) => sc.value === value)) {
      this.serviceCenter = '';
      return { invalid: true };
    }
    return null;
  }

  writeValue(serviceCenter: any): void {
    if (serviceCenter) {
      this.serviceCenter = serviceCenter;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  displayValue(value?: string): string | undefined {
    if (this.serviceCenters.length && value) {
      const center = this.serviceCenters.find((sc) => sc.value === value);
      return center ? center.viewValue : undefined;
    }
  }

  selectionChange(serviceCenter) {
    if (this.control && this.control.value) {
      this.changeSic.emit(this.control.value);
    } else {
      this.changeSic.emit('');
    }
  }

  onOptionSelected(e): void {
    (document.activeElement as HTMLElement).blur();
    this.filterOptions();
    if (this.requestAdmin) {
      this.setZones(e.option.value);
    }
    this.changeSic.emit(this.control.value);
  }

  filterOptions(): void {
    this.options$ = this.control.valueChanges.pipe(
      startWith(''),
      map((sc) => this.filterServiceCenters(sc))
    );
  }

  private setZones(optionSelected) {
    const userDomicileSicCd = this.userService.user && this.userService.user.sicCode;
    const queryParams = new GetZoneAndSatelliteBySicQuery();
    queryParams.zoneInd = true;
    queryParams.satelliteInd = true;
    const path = {
      sicCd: this.requestAdmin ? optionSelected : this.userService.user.sicCode,
    } as GetZoneAndSatelliteBySicPath;
    this.locationApiService
      .getZoneAndSatelliteBySic(path, queryParams)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((zones: any) => {
        if (!this.requestAdmin) {
          if (this.type === 'special-rules') {
            const customAssignmentLabel = this.locationServiceRules.getCustomAssignmentLocationLabel();
            this.serviceCenters.push(
              ...customAssignmentLabel.map((custom) => {
                return {
                  value: custom,
                  viewValue: custom,
                };
              })
            );
          } else {
            zones.zoneSatelliteInfo = zones.zoneSatelliteInfo.filter((zone) => !zone.satelliteParent);
            this.customAssignmentElectronic = this.locationServiceRules.getCustomAssignmentLocationElectronic();
            this.customAssignmentElectronic.forEach((element) => {
              if (element.zones.length) {
                zones.zoneSatelliteInfo = [...zones.zoneSatelliteInfo, ...element.zones];
              }
              this.serviceCenters.push({
                value: element.allowedSic,
                viewValue: element.allowedSic,
              });
            });
            this.zonesEmitter.emit(zones.zoneSatelliteInfo);
            this.serviceCenters.push(
              {
                value: userDomicileSicCd,
                viewValue: userDomicileSicCd,
              },
              ...zones.zoneSatelliteInfo.map((zone) => {
                return {
                  value: zone.locationSic,
                  viewValue: `${zone.locationSic} zone for ${userDomicileSicCd}`,
                };
              })
            );
          }
          this.control.setValue(this.serviceCenter);
          this.isLoading = false;
        }
      });
  }

  protected setServiceCenter(): void {
    const queryParams = new GetZoneAndSatelliteBySicQuery();
    queryParams.zoneInd = true;
    queryParams.satelliteInd = true;
    const path = {
      sicCd: this.serviceCenter,
    } as GetZoneAndSatelliteBySicPath;

    const query = {
      activeInd: true,
      satelliteDesiredInd: !this.physicalLocOnly,
      zoneDesiredInd: true,
      zoneWithPhysicalAddressInd: this.physicalLocOnly,
    } as ListLocationsQuery;

    this.locationApiService
      .listLocations(new ListLocationsRqst(), query)
      .pipe(
        take(1),
        switchMap((response: any) => {
          if (this.serviceCenter) {
            return this.locationApiService
              .getZoneAndSatelliteBySic(path, queryParams)
              .pipe(map((zones: any) => ({ response, zones: zones.zoneSatelliteInfo })));
          } else {
            return of({ response, zones: null });
          }
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(({ response, zones }) => {
        if (zones) {
          this.zonesEmitter.emit(zones);
        }
        /* istanbul ignore else */
        if (response && response.locationSic) {
          this.serviceCenters.push(
            ...response.locationSic.map((sc) => ({
              value: sc.sicCd,
              viewValue: `${sc.sicCd} - ${sc.sicDescription}`,
            }))
          );
        }
        if (this.userService.isBasicUser) {
          const userSic = this.userService.user.sicCode;
          this.serviceCenter = !this.isHost(userSic) ? '' : userSic;
        }
        this.control.setValue(this.serviceCenter || '');
      });
  }

  protected isHost(userSic): boolean {
    return this.serviceCenters.some((sc) => {
      return sc.value === userSic;
    });
  }

  private filterServiceCenters(sc: string): any {
    const filterValue = sc.toLowerCase();
    return this.serviceCenters.filter((option) => option.viewValue.toLowerCase().includes(filterValue));
  }

  protected checkRole() {
    if (this.userRole) {
      this.readOnlySic = this.userHasPermission([UserRole.User]);
    }
  }

  userHasPermission(userGroups: Array<string>): boolean {
    return userGroups.includes(this.userRole);
  }

  inputChange(event: Event) {
    const value = event.target['value'];
    if (!value) {
      this.changeSic.emit('');
    } else {
      this.changeSic.emit(value);
    }
  }
}

export class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return !!(control && control.invalid && (control.dirty || control.touched));
  }
}
