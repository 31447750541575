import { Component, Input } from '@angular/core';
import { ILoadingOverlayAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-loading-overlay',
  template: `
    <div style="height: 100%; width: 100%; background-color: white; margin-top: 40px">
      <mat-progress-spinner [mode]="'indeterminate'" color="primary" diameter="40"> </mat-progress-spinner>
    </div>
  `,
})
export class GridLoadingComponent implements ILoadingOverlayAngularComp {
  private params: any;

  agInit(params): void {
    this.params = params;
  }
}
