import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { FormControl } from '@angular/forms';
import { UnsubscribeComponent } from '../../components/unsubscribe/unsubscribe.component';
import { MatSelectChange } from '@angular/material';

@Component({
  moduleId: module.id,
  selector: 'rush-cell-renderer',
  templateUrl: 'rush-cell-renderer.component.html',
  styleUrls: ['rush-cell-renderer.component.scss'],
})
export class RushCellRendererComponent implements ICellRendererAngularComp {
  selected: any = null;
  options: any[];
  isGroup: false;
  params: any;
  isNewOrInProcess = false;
  selectedRushInd: string;
  isEnabled = false;
  isEditable = false;
  rushInd: FormControl;
  gridBody: HTMLElement;

  constructor() {}

  agInit(params: any): void {
    this.options = [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ];
    this.isNewOrInProcess = params.isNewOrInProcess;
    this.isEnabled = params.isEnabled;
    this.isEditable = params.isEditable;
    this.params = params;
    this.selected = params.value;
    this.selectedRushInd = params.value;
    this.rushInd = new FormControl(this.params.value);
  }

  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  change($event: MatSelectChange) {
    if (this.params.onSelectionChanged instanceof Function) {
      const params = {
        event: $event.value,
        rowData: this.params.node,
      };
      this.params.onSelectionChanged(params);
    }
  }
}
