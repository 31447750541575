import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Host,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  GetZoneAndSatelliteBySicPath,
  GetZoneAndSatelliteBySicQuery,
  ListLocationsQuery,
  ListLocationsResp,
  ListLocationsRqst,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UserService } from '../../../core/services/user.service';
import { UserRole } from '../../enums/user-role/user-role.enum';
import { LocationService } from '../../../core/services/location.service';
import { SicInputComponent } from '../sic-input/sic-input.component';
import { ControlContainer, ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-physical-sic-input',
  templateUrl: './physical-sic-input.component.html',
  styleUrls: ['./physical-sic-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PhysicalSicInputComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PhysicalSicInputComponent,
      multi: true,
    },
  ],
})
export class PhysicalSicInputComponent extends SicInputComponent
  implements OnInit, AfterViewChecked, AfterViewInit, ControlValueAccessor {
  serviceCenters: any[] = [];
  serviceCenter: string = null;
  isLoading: boolean = true;

  constructor(
    @Optional()
    @Host()
    @SkipSelf()
    protected controlContainer: ControlContainer,
    protected userService: UserService,
    protected changeDetector: ChangeDetectorRef,
    protected locationApiService: LocationApiService,
    protected locationServiceRules: LocationService
  ) {
    super(controlContainer, userService, changeDetector, locationApiService, locationServiceRules);
  }

  ngAfterViewInit() {
    this.setServiceCenter();
    this.checkRole();
  }

  protected setServiceCenter(): void {
    const queryParams = new GetZoneAndSatelliteBySicQuery();
    queryParams.zoneInd = true;
    queryParams.satelliteInd = true;
    const path = {
      sicCd: this.requestAdmin ? this.control.value : this.userService.user.sicCode,
    } as GetZoneAndSatelliteBySicPath;

    const query = {
      activeInd: true,
      satelliteDesiredInd: false,
      zoneDesiredInd: true,
      zoneWithPhysicalAddressInd: true,
    } as ListLocationsQuery;

    this.locationApiService
      .listLocations(new ListLocationsRqst(), query)
      .pipe(
        take(1),
        switchMap((response: ListLocationsResp) => {
          if (this.control.value) {
            return this.locationApiService.getZoneAndSatelliteBySic(path, queryParams).pipe(
              map((zones) => {
                return {
                  response,
                  zones: zones.zoneSatelliteInfo.filter((zn) => !!zn.zoneTerminal && !zn.satelliteParent),
                };
              })
            );
          } else {
            return of({ response, zones: [] });
          }
        }),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(({ response, zones }) => {
        const locationsSic = response && response.locationSic ? response.locationSic : [];
        const sicSet = new Set<string>(locationsSic.map((location) => location.sicCd));
        if (this.userRole === UserRole.User) {
          sicSet.clear();
          sicSet.add(this.userService.user.sicCode);
          zones.forEach((zone) => sicSet.add(zone.locationSic));
        }
        if (this.locationServiceRules.hasCustomAssignmentLocationLabel()) {
          const customAssignmentLabels = this.locationServiceRules.getCustomAssignmentLocationLabel();
          customAssignmentLabels.forEach((customAssignmentLabel) => sicSet.add(customAssignmentLabel));
        }
        this.serviceCenters = Array.from(sicSet).map((sicCode) => ({ value: sicCode, viewValue: sicCode }));
        if (this.userService.isBasicUser) {
          const userSic = this.userService.user.sicCode;
          this.serviceCenter = !this.isHost(userSic) ? '' : userSic;
        }
        this.control.setValue(this.serviceCenter || '');
      });
  }
}
