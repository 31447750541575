import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  GetUserPreferenceQuery,
  UpsertUserPreferenceQuery,
  UpsertUserPreferenceRqst,
  UserPreferenceApiService,
} from '@xpo-ltl/sdk-userpreference';
import * as _ from 'lodash';
import { UserService } from '../core/services/user.service';
import { UserRole } from '../shared/enums/user-role/user-role.enum';
import { Router } from '@angular/router';
import { CacheHelperService } from '../core/services/cache-helper.service';
import { DashboardType } from '../shared/enums/dashboard-type.enum';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeComponent } from '../shared/components/unsubscribe/unsubscribe.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent extends UnsubscribeComponent implements OnInit, OnDestroy {
  lastAccessed = '';
  private readonly componentName = 'LandingPage';
  userRole: string;

  constructor(
    private userPreferenceService: UserPreferenceApiService,
    private userService: UserService,
    private router: Router,
    private cacheHelper: CacheHelperService
  ) {
    super();
  }

  ngOnInit() {
    this.userService
      .getLoggedInUser$()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((user) => {
        this.redirectRole();
        const getPreferencesQuery = new GetUserPreferenceQuery();
        getPreferencesQuery.uiComponentName = this.componentName;
        this.userPreferenceService
          .getUserPreference(getPreferencesQuery, { loadingOverlayEnabled: false })
          .subscribe(async (getPreferencesResponse) => {
            const json = _.get(getPreferencesResponse, 'preferences', '');
            const preferences = JSON.parse(json && json.length > 0 ? json : '{}');
            this.lastAccessed = _.get(preferences, 'lastAccessed', '');
            await this.updateUserPreferences()
              .then(() => {
                console.log('User preferences updated!');
              })
              .catch((error) => {
                console.log('ERROR');
              });
          });
      });
  }

  private redirectRole(): void {
    this.userRole = this.userService.getRole();
    switch (this.userRole) {
      case UserRole.Admin:
      case UserRole.Sales:
      case UserRole.Manager:
        this.cacheHelper.recordTab(DashboardType.Request, 'Electronic PRO Numbers');
        this.router.navigate(['./dashboards/requests-dashboard']);
        break;
      case UserRole.User:
        this.router.navigate(['./dashboards/inventory-dashboard']);
        break;
      default:
        this.router.navigate(['./dashboards/inventory-dashboard']);
    }
  }

  private async updateUserPreferences(): Promise<any> {
    const upsertPreferencesRequest = new UpsertUserPreferenceRqst();
    upsertPreferencesRequest.uiComponentName = this.componentName;
    upsertPreferencesRequest.preferences = JSON.stringify({ lastAccessed: new Date() });
    const upsertPreferencesQuery = new UpsertUserPreferenceQuery();
    return this.userPreferenceService
      .upsertUserPreference(upsertPreferencesRequest, upsertPreferencesQuery)
      .toPromise();
  }
}
