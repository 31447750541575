import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { ErrorComponent } from './app-error.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { BrowserGuard } from './shared/guards/browser/browser.guard';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.LANDING_PAGE,
    component: LandingPageComponent,
    // FOR SSO
    canActivate: [XpoAuthenticationGuard, BrowserGuard],
  },
  {
    path: 'e-labels',
    loadChildren: () => import('./e-labels/e-labels.module').then((m) => m.ELabelsModule),
    canActivate: [XpoAuthenticationGuard, BrowserGuard],
  },
  {
    path: 'pro-labels',
    loadChildren: () => import('./pro-labels/pro-labels.module').then((m) => m.ProLabelsModule),
    canActivate: [XpoAuthenticationGuard, BrowserGuard],
  },
  {
    path: 'dashboards',
    loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
    canActivate: [XpoAuthenticationGuard, BrowserGuard],
  },
  {
    path: AppRoutes.ADMIN_PAGE,
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [XpoAuthenticationGuard, BrowserGuard],
    // FOR SSO
    // canLoad: [XpoAuthenticationGuard],
    // data: { roles: [UserRole.Admin] },
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  // ADD ANY NEW ROUTE / PAGE HERE
  {
    path: '**',
    redirectTo: `/${AppRoutes.LANDING_PAGE}`,
    canActivate: [XpoAuthenticationGuard, BrowserGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
