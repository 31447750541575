import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ColDef, GridApi, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { GridLoadingComponent } from '../../../dashboards/shared/components/grid-loading/grid-loading.component';
import { GridMetrics } from './models/grid-metrics';
import { ProPadTypeCd } from '@xpo-ltl/sdk-common';

@Component({
  moduleId: module.id,
  selector: 'app-grid-metrics',
  templateUrl: 'grid-metrics.component.html',
  styleUrls: ['grid-metrics.component.scss'],
})
export class GridMetricsComponent implements OnInit, OnChanges {
  constructor() {}
  @Input() metrics: GridMetrics[];
  @Input() categoryName: string = '';

  gridOptions: GridOptions = {
    defaultColDef: <ColDef>{
      resizable: false,
      filter: true,
      suppressMovable: true,
    },
    frameworkComponents: {
      gridLoadingComponent: GridLoadingComponent,
    },
    onGridReady: this.onGridReady.bind(this),
    loadingOverlayComponent: 'gridLoadingComponent',
    suppressRowClickSelection: true,
    suppressCellSelection: true,
    domLayout: 'autoHeight',
    rowHeight: 30,
    headerHeight: 35,
    suppressHorizontalScroll: true,
  };
  columnDefs: ColDef[] = [];
  gridData = [];
  private gridApi: GridApi;

  private static labelFormatter(params) {
    switch (params.value) {
      case ProPadTypeCd.CA_BLUE_YELLOW:
        return 'CA, Blue & Yellow = Parent / Child';
      case ProPadTypeCd.US_BLUE_YELLOW:
        return 'US, Blue & Yellow = Parent / Child';
      case ProPadTypeCd.CA_BLUE:
        return 'CA, Blue = Customer';
      case ProPadTypeCd.US_BLUE:
        return 'US, Blue = Customer';
      case ProPadTypeCd.YELLOW:
        return 'Yellow = Child';
      default:
        return params.value;
    }
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.gridApi.showLoadingOverlay();
    this.gridApi.sizeColumnsToFit();
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    };
  }

  ngOnInit(): void {
    this.columnDefs = [
      {
        headerName: this.categoryName,
        field: 'category',
        editable: false,
        valueFormatter: GridMetricsComponent.labelFormatter,
      },
      {
        headerName: '6 months Usage',
        field: 'usageSum',
        editable: false,
        sortable: true,
        valueFormatter: (params) => params.value || 0,
      },
      {
        headerName: 'Unused PRO labels',
        field: 'unused',
        editable: false,
        sortable: true,
        valueFormatter: (params) => params.value || 0,
      },
      {
        headerName: 'Eligible for Order',
        field: 'forecastWeeks',
        editable: false,
        sortable: true,
        valueFormatter: (params) => params.value || 'Unavailable',
      },
    ];
  }

  ngOnChanges() {
    if (this.metrics) {
      this.gridData = this.metrics;
    }
  }
}
