import { Injectable } from '@angular/core';
import { catchError, takeUntil } from 'rxjs/operators';
import { Observable, of, BehaviorSubject } from 'rxjs';
import {
  ProNumberReengineeringApiService,
  ListCustomAssignmentLocationResp,
} from '@xpo-ltl/sdk-pronumberreengineering';
import {
  GetZoneAndSatelliteBySicQuery,
  GetZoneAndSatelliteBySicPath,
  LocationApiService,
} from '@xpo-ltl-2.0/sdk-location';

@Injectable({ providedIn: 'root' })
export class LocationService {
  private customAssignmentElectronic = [];
  shipToSicMap = new Map<string, string>();

  constructor(
    private proNumberReengineeringApiService: ProNumberReengineeringApiService,
    private locationApiService: LocationApiService
  ) {}

  loadCustomAssignmentLocation(userSic: string, isAdmin: boolean = false) {
    this.proNumberReengineeringApiService
      .ListCustomAssignmentLocation()
      .pipe(catchError(this.handleError))
      .subscribe((data: ListCustomAssignmentLocationResp) => {
        if (data.customAssignmentLocations) {
          this.saveCustomAssignmentLocation(data.customAssignmentLocations, userSic, isAdmin);
        }
      });
  }

  private saveCustomAssignmentLocation(locParams: string[], userSic: string, isAdmin: boolean) {
    if (!isAdmin) {
      this.shipToSicMap.set(userSic, userSic);
    }
    locParams.forEach((element) => {
      const current = element.split(' | ');
      const type = current[0];
      const hostSic = current[1];
      const allowedSic = current[2];
      const shipToSic = current[3];
      if (type === 'E' && userSic === hostSic) {
        this.getZones(allowedSic);
      } else if ((type === 'L' && userSic === hostSic) || isAdmin) {
        this.shipToSicMap.set(allowedSic, shipToSic);
      }
    });
  }

  private getZones(allowedSic: string) {
    const queryParams = new GetZoneAndSatelliteBySicQuery();
    queryParams.zoneInd = true;
    const path = {
      sicCd: allowedSic,
    } as GetZoneAndSatelliteBySicPath;
    this.locationApiService
      .getZoneAndSatelliteBySic(path, queryParams)
      .pipe(catchError(this.handleError))
      .subscribe((response: any) => {
        this.customAssignmentElectronic.push({ allowedSic: allowedSic, zones: response.zoneSatelliteInfo });
      });
  }

  getCustomAssignmentLocationLabel(): string[] {
    return Array.from(this.shipToSicMap.keys());
  }

  hasCustomAssignmentLocationLabel(): boolean {
    return this.shipToSicMap.size > 0;
  }

  getCustomAssignmentLocationElectronic() {
    return this.customAssignmentElectronic;
  }

  handleError(err: any) {
    return of(null);
  }
}
